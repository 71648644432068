import React, { useState } from 'react';
import { Buffer } from 'buffer';
import * as XLSX from 'xlsx';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import CollaboratorGrid from '../../components/grids/CollaboratorBatchingLog';
import Header from '../../components/Header';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAccountHoldersByCompanyId } from '../../store/_Entities/AccountHolder';

// Assets
import { postBatch, getBatching, getBatchingFile } from '../../store/_Entities/Batching';

const BatchAddCollaborator = () => {
    const { user, batching } = useSelector((state) => state.entities);
    const [ file, setFile ] = useState();
    const [ selectedFileName, setSelectedFileName ] = useState('');
    const [ batchingRows, setBatchingRows ] = useState([]);

    const saveFile = (e) => {
        setFile(e.target.files[0])
        setSelectedFileName(e.target.files[0].name)
    }

    const dispatch = useDispatch();

    const forceBeneflex = async (blobFile) => {
        try {
            const arrayBuffer = await blobFile.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonList = XLSX.utils.sheet_to_json(sheet);

            
            jsonList.forEach((row) => {
                row[`Beneflex (Sim ou Nao)`] = 'Sim';
                row[`Cartao Combustivel (Sim ou Nao)`] = 'Nao';
                row[`Corporativo (Sim ou Nao)`] = 'Nao';
                row[`Driver (Sim ou Nao)`] = 'Nao';
                row[`Expen (Sim ou Nao)`] = 'Nao';
                row[`Frota Propria (Sim ou Nao)`] = 'Nao';
                row[`KM (Sim ou Nao)`] = 'Nao';
            }) 

            const excelData = XLSX.utils.json_to_sheet(jsonList);
            const workbookFinal = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbookFinal, excelData, 'Sheet1');
            const excelBuffer = XLSX.write(workbookFinal, { bookType: 'xlsx', type: 'array' });
            const fileBlobFinal = new File([excelBuffer], blobFile.name ,{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            
            return fileBlobFinal;

        } catch (error) {
            console.error('Erro ao processar o arquivo:', error);
            return blobFile;
        }
    }

    
    const submitBatch = async () => {
        const formData = new FormData();

        formData.append('companyID', user.data.companyID);
        formData.append('userID', user.data.userID);
        formData.append('batchingTypeID', 13);
        
        const fileBlobFinal = await forceBeneflex(file);
        
        formData.append('file', fileBlobFinal);
        dispatch(postBatch(user.data.companyID, user.data.userID, 13, fileBlobFinal))
    }

    React.useMemo(() => {
        dispatch(GetAllAccountHoldersByCompanyId(user.data?.companyID));
    }, []);

    React.useEffect(() => {
        dispatch(getBatching(13, user.data?.companyID));
        dispatch(getBatchingFile());  
    }, []);

    const getFile = () => {
        const byteCharacters = window.atob(batching.batchFile.fileContents);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'application/vnd.ms-excel'});
        const blobUrl = URL.createObjectURL(blob);

        const link = document.getElementById('downloadFile');
        link.href = blobUrl;
    }


    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Adição de Colaboradores em lote"/>
                <Grid container rowGap={8}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: '1rem'
                        }}
                    >   
                        
                        <Grid>
                            <Button style={{padding: '8px 16px'}} variant="contained" component="span" type='button' onClick={submitBatch}>
                                Enviar
                            </Button>
                        </Grid>
                        <Grid>
                            <label htmlFor="contained-button-file">
                                <input 
                                    style={{ display: 'none' }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                                    id="contained-button-file" 
                                    type="file" 
                                    onChange={saveFile}
                                />
                                <Button variant="contained" component="span" style={{padding: '8px 16px'}}>
                                    Upload
                                </Button>
                            </label>
                        </Grid>
                        <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{selectedFileName}</Grid>
                        <Grid xs={12} sx={{ marginTop: '15px', width: '50%' }}>
                            <Typography
                                gutterBottom
                                variant="body2"
                                component="div"
                                sx={{
                                    fontSize: '15px',
                                    color: '#686868'
                                }}
                            >
                                Faça o download de um exemplo <a href='/' onClick={getFile} download={"exemplo_lote_usuarios_banco.xlsx"} id="downloadFile">aqui</a>.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <CollaboratorGrid
                            data={batching.batching || []}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default BatchAddCollaborator;
