// export const AuthServiceUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
export const AuthServiceUrl = 'https://wexp-authserver.azurewebsites.net';
// export const GatewayUrl = process.env.REACT_APP_GATEWAY_URL;
export const GatewayUrl = 'https://wexp-wbankgateway.azurewebsites.net/';
export const GatewayApiUrl = GatewayUrl + 'api';

export const wExpoGatewayUrl =
  'https://wexp-wexpogateway.azurewebsites.net/';
export const wExpoGatewayApiUrl = wExpoGatewayUrl + 'api';

export const Endpoints = {
    Auth: {
        ConnectToken: `${AuthServiceUrl}/connect/token`
    },
    Address: {
        CompanyAddress: `${GatewayApiUrl}/Address/`
    },
    Benefits: {
        GetCompanyBenefits: `${GatewayApiUrl}/Benefits/CompanyBenefits`,
        ChangeBenefitStatus: `${GatewayApiUrl}/Benefits/ChangeBenefitStatus`,
        ChangeBenefitAllowedTransferStatus: `${GatewayApiUrl}/Benefits/ChangeBenefitAllowedTransferStatus`
    },
    AccountHolder: {
        GetIndividualAccountHolder: (userId) =>
            `${GatewayApiUrl}/AccountHolder/GetIndividualAccountHolder/${userId}`,
        GetAdministrators: (companyId) =>
            `${GatewayApiUrl}/AccountHolder/GetAdministrators/${companyId}`,
        ValidateCaptcha: `${GatewayApiUrl}/AccountHolder/ValidateCaptcha/`
    },
    BalanceAccountGroup: {
        GetUserBalanceGroup: `${GatewayApiUrl}/BalanceAccountGroup/UserBalanceAccountGroup`
    },
    CostCenter: {
        GetCostCenters: (companyID) =>
            `${wExpoGatewayApiUrl}/CostCenter?CompanyID=${companyID}&active=1`,
    },
    Transfer: {
        TreasuryAccount: `${GatewayApiUrl}/Transfer/TreasuryAccount`,
        ReturnBalanceTransfer: `${GatewayApiUrl}/Transfer/ReturnBalanceTransfer`,
        GenerateBoleto: `${GatewayApiUrl}/Boleto/GenerateBoleto`,
        GetCompanyOrders: (companyID, OrderTypeID) =>
            `${GatewayApiUrl}/Transfer/Order/Company?CompanyID=${companyID}&OrderTypeID=${OrderTypeID}`,
        UpdateAprroveTransfer: `${GatewayApiUrl}/Transfer/ApproveTransfer`,
        GetApproveTranfer: (companyID) =>
            `${GatewayApiUrl}/Transfer/PendingApproval?CompanyID=${companyID}`,
        DownloadBoleto: (billingID) =>
            `${GatewayApiUrl}/Boleto/DownloadBoleto/${billingID}`
    },
    Batching: {
        PostUserBatching: `${GatewayApiUrl}/Batching/PostUserBatching`,
        PostTransferBatching: `${GatewayApiUrl}/Batching/PostTransferBatching`,
        GetBatching: `${GatewayApiUrl}/Batching/GetBatching`,
        GetBatchingLog: `${GatewayApiUrl}/Batching/GetBatchingLog`,
        GetBatchingFile: (batchingTypeID) =>
            `${wExpoGatewayApiUrl}/Batching?BatchingTypeID=${batchingTypeID}`,
        GetTransferFile: (companyID) =>
            `${GatewayApiUrl}/Batching/GetTransferFile/${companyID}`
    },
    InviteUser: {
        GetInviteUsers: (companyID) =>
            `${wExpoGatewayApiUrl}/InviteUsers?CompanyID=${companyID}`,
        GetInviteUsersByUserID: (userID) =>
            `${wExpoGatewayApiUrl}/InviteUsers/${userID}`,
        ResendInviteUser: (inviteUserID, userID) =>
            `${wExpoGatewayApiUrl}/InviteUsers/Resend?InviteUsersID=${inviteUserID}&UserID=${userID}`,
        DeleteInviteUser: (inviteUserID, userID) =>
            `${wExpoGatewayApiUrl}/InviteUsers/${inviteUserID}/Delete?UserID=${userID}`,
        CreateInviteUser: (userID) =>
            `${wExpoGatewayApiUrl}/InviteUsers?UserID=${userID}`,
    },
    Statement: {
        GetCompanyTransfer: (companyId) =>
            `${GatewayApiUrl}/Statement/GetCompanyTransferStatement/${companyId}`,
        GetCompanyPMATransfer: (companyId) =>
            `${GatewayApiUrl}/Statement/GetCompanyPMATransferStatement/${companyId}`,
        GetTreasuryAccountStatementV2: (companyId, startDate, endDate) =>
            `${GatewayApiUrl}/Statement/GetTreasuryAccountStatementV2/${companyId}?StartDate=${startDate}&EndDate=${endDate}`
    },
    BalanceReturnConfiguration: {
        GetUserBalanceReturnConfig: `${GatewayApiUrl}/BalanceReturnConfiguration/UserConfiguration`
    },
    CompanyTransactions: {
        GetCompanyTransactions: `${GatewayApiUrl}/Backoffice/CompanyTransactions`,
        ExportReport: `${GatewayApiUrl}/Backoffice/Export`
    },
    Pix: {
        PostPixFundsIn: `${GatewayApiUrl}/Pix/CreatePixStaticQRCode`
    }
};
