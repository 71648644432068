import React, { useState, useEffect } from 'react';
import { DateTime } from "luxon";

// Wexp Components
import Header from '../components/Header';
import ResponsiveAppBar from '../components/NavBar';
import Footer from '../components/Footer';
import TransactionGrid from '../components/grids/TransactionGrid';
import { filterByDate } from '../utils/filterByDate';
import BenefitsSelector from "../components/BenefitsSelector";
import CompanyUsersSelector from "../components/CompanyUsersSelector";
import LoadingAction from "../components/LoadingAction";
import { exportCSVFile } from "../utils/download";

// MUI
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    Divider,
    Modal,
    Box,
    Select
} from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ptLocale from "date-fns/locale/pt-BR";

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetTreasuryAccountHolder } from '../store/_Entities/TreasuryAccountHolder';
import { fromFloatToCurrency } from '../utils/format';
import { GetCompanyTransactions, ExportReport } from "../store/_Entities/CompanyTransactions";
import { GetAllAccountHoldersByCompanyId } from '../store/_Entities/AccountHolder';

const csvHeader = {
    user: 'Usuario',
    addedTime: 'Data da transacao',
    currency: 'Moeda',
    txnAmount: 'Valor Pago',
    billingAmountAuthorized: 'Valor Convertido',
    mcc: 'MCC',
    merchantName: 'Descricao',
    merchantCity: 'Cidade',
    panMasked: 'Numero do Cartao',
}

const Transaction = () => {
    const { companyTransactions, user } = useSelector(
        (state) => state.entities
    );
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);

    const { companyAccountHolders } = useSelector(
        (state) => state.entities.accountHolder
    );

    const modalStyle = {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        textAlign: "center",
        p: 4,
    };

    // filtro default de 1 ano
    var defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    defaultStartDate.setHours(0, 0, 0, 0);

    var defaultEndDate = new Date();
    defaultEndDate.setHours(0, 0, 0, 0);

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [benefitID, setBenefitID] = useState(0);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [userFilter, setUserFilter] = useState("");
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        filterTransactions();
    }, [skip]);


    useEffect(() => {
        if (companyTransactions) {
            setTotal(companyTransactions?.companyTransactions?.total)
        }

        if (companyTransactions.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }

    }, [companyTransactions]);

    React.useEffect(() => {
        dispatch(GetAllAccountHoldersByCompanyId(user.data.companyID));
    }, []);

    const filterTransactions = () => {

        let startDateReq = DateTime.fromISO(startDate.toISOString())
        .set({ hour: 0, minute: 0, second: 0 })
        .toUTC()
        .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

        let endDateReq = DateTime.fromISO(endDate.toISOString())
        .set({ hour: 23, minute: 59, second: 59 })
        .toUTC()
        .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

        
        let benefitIDReq = benefitID != 0 ? benefitID : null;

        dispatch(GetCompanyTransactions('2', startDateReq, endDateReq, user.data.companyID, skip, userFilter, null, benefitIDReq));

    }

    const sendFileByEmail = () => {
        let startDateReq = DateTime.fromISO(startDate.toISOString()).toFormat('yyyy-MM-dd');
        let endDateReq = DateTime.fromISO(endDate.toISOString()).toFormat('yyyy-MM-dd');
        let benefitIDReq = benefitID != 0 ? benefitID : null;
        let userID = userFilter !== "0" ? userFilter : null;

        let data = {
            userID: userID,
            companyID: user.data.companyID,
            company: user.data.company,
            productID: 2,
            benefitID: benefitIDReq,
            startDate: startDateReq,
            endDate: endDateReq,
            email: user.data.email
        }

        dispatch(ExportReport(data));
        setOpenConfirmationModal(true)

    }

    const handleClose = () => {
        setOpenConfirmationModal(false);
    };


    return (
        <>
            <ResponsiveAppBar />

            <Container spacing={11}>
                <Header backButton title="Relatório de transações" />

                <Grid item xs={12}>
                    <Grid
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            marginBottom: '20px',
                            flexDirection: { xs: 'column', md: 'row' }
                        }}
                    >
                        <Grid item xs={12} md={2}>
                            <CompanyUsersSelector
                                required
                                id="state"
                                users={companyAccountHolders}
                                setUserFilter={setUserFilter}

                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <BenefitsSelector
                                required
                                id="state"
                                setBenefitID={setBenefitID}
                            />
                        </Grid>
                        <Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="De"
                                    views={["year", "month", "day"]}
                                    inputFormat="dd/MM/yyyy"
                                    mask='__/__/____'
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="Até"
                                    views={["year", "month", "day"]}
                                    inputFormat="dd/MM/yyyy"
                                    mask='__/__/____'
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid>
                            <Button variant="contained" sx={{ height: '100%' }} onClick={() => filterTransactions()}>
                                Filtrar
                            </Button>
                        </Grid>
                    </Grid>

                    <Divider sx={{ margin: '60px 0' }} />

                    <Grid container xs={12} justifyContent="end" sx={{ cursor: "pointer" }}>
                        <Link
                            onClick={() => sendFileByEmail()}
                        >
                            Exportar CSV
                        </Link>
                    </Grid>

                    <Grid>
                        <LoadingAction loading={loading} />
                    </Grid>

                    <TransactionGrid companyTransactions={companyTransactions?.companyTransactions} setSkip={setSkip} total={total} />

                    <Modal
                        open={openConfirmationModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="modal-login" sx={modalStyle}>
                            <Typography id="modal-modal-title" variant="h6">
                                Em até 5 minutos o relatório será enviado para o e-mail.
                            </Typography>
                            <div style={{ display: "flex", gap: "1.5rem", marginTop: "2rem" }}>
                                <Button size="medium" variant="contained" onClick={handleClose}>
                                    Fechar
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </Grid>
            </Container>

            <Footer />
        </>
    );
};

export default Transaction;
