import { createSlice } from '@reduxjs/toolkit';
import { IState } from './interfaces/CostCenter'; 
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    costCenters: [],
};

export const slice = createSlice({
    name: 'costCenter',
    initialState,
    reducers: {
        COST_CENTER_REQUESTED: (state,action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        COST_CENTER_FAILED: (state,action) => {
            state.loading = false;
            state.error = true;
            state.success = false;
        },
        COST_CENTER_SUCCESS: (state,action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
            state.costCenters = action.payload.result;
        },
    }
})

export const { COST_CENTER_REQUESTED, COST_CENTER_FAILED, COST_CENTER_SUCCESS } = slice.actions;

export default slice.reducer;

export const GetCostCenters = (CompanyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.CostCenter.GetCostCenters(CompanyID),
        },
        onAction: {
            onStart: COST_CENTER_REQUESTED.type,
            onError: COST_CENTER_FAILED.type,
            onSuccess: COST_CENTER_SUCCESS.type,
        }
    })
}

