import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import CollaboratorGrid from '../../components/grids/CollaboratorGrid';
import Header from '../../components/Header';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAccountHoldersByCompanyId } from '../../store/_Entities/AccountHolder';

const Collaborators = () => {
    const { user, accountHolder } = useSelector((state) => state.entities);

    const dispatch = useDispatch();

    React.useMemo(() => {
        dispatch(GetAllAccountHoldersByCompanyId(user.data?.companyID));
    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Colaboradores" />
                <Grid container rowGap={8}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: '1rem'
                        }}
                    >
                        <Button href={`/collaborators/invite/${user.data?.companyID}`} variant="contained">
                            Cadastrar Colaborador
                        </Button>
                        <Button href="/collaborators/batching"  variant="contained">
                            Cadastrar Colaborador em lote
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <CollaboratorGrid
                            data={accountHolder.companyAccountHolders}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Collaborators;
