import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Endpoints, GatewayApiUrl } from '../../constants/endpoints';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { apiCallBegan } from '../api';
import { IBatchingState, IBatching, IBatchingLog } from './interfaces/Batching';

const initialState: IBatchingState = {
    loading: false,
    success: false,
    error: false,
    batchFile: "",
    batching: [],
    selectedBatchingLog: []
}

const slice = createSlice({
    name: 'batching',
    initialState,
    reducers: {
        BATCH_REQUESTED: (
            batching : IBatchingState, 
            action: PayloadAction<IRequestResponse<IBatchingState>>
        ) => {
            batching.loading = true;
            batching.success = false;
            batching.error = false;
        },
        BATCH_SUCCESS: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<IBatchingState>>
        ) => {
            batching.loading = false;
            batching.success = true;
            batching.error = false;
        },
        BATCH_FAILED: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<any>>
        ) => {
            batching.loading = false;
            batching.success = false;
            batching.error = true;
        },
        GET_BATCH_SUCCESS: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<IBatching[]>>
        ) => {
            batching.loading = false;
            batching.success = true;
            batching.error = false;
            batching.batching = [];
            batching.batching = action.payload.result;
            batching.selectedBatchingLog = []
        },
        POST_BATCH_SUCCESS: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<IBatching>>
        ) => {
            batching.loading = false;
            batching.success = true;
            batching.error = false;
            batching.batching = [...batching.batching, action.payload.result]
        },
        GET_BATCHING_LOG_SUCCESS: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<IBatchingLog[]>>
        ) => {
            batching.loading = false;
            batching.success = true;
            batching.error = false;
            batching.selectedBatchingLog = action.payload.result;
        },
        CLEAR_BATCHING_LOG: (
            batching: IBatchingState
        ) => {
            batching.selectedBatchingLog = [];
        },
        GET_BATCH_FILE: (
            batching: IBatchingState, 
            action: PayloadAction<IRequestResponse<any>>
        ) => {
            batching.loading = false;
            batching.success = true;
            batching.error = false;
            batching.batchFile = action.payload.result;
        },
    }
});

export const { BATCH_REQUESTED, BATCH_SUCCESS, BATCH_FAILED, GET_BATCH_SUCCESS, GET_BATCHING_LOG_SUCCESS, CLEAR_BATCHING_LOG, GET_BATCH_FILE, POST_BATCH_SUCCESS } =
    slice.actions;

export default slice.reducer;

export const postBatch = (companyID: number, userID: string, batchingTypeID: number, file: Blob): any => {
    const formData = new FormData();

    formData.append('companyID', companyID.toString());
    formData.append('userID', userID);
    formData.append('batchingTypeID', batchingTypeID.toString());
    formData.append('file', file);

    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Batching.PostUserBatching,
            data: formData
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: POST_BATCH_SUCCESS.type,
            onError: BATCH_FAILED.type,
            showToast: true
        }
    });
};

export const postTransferBatch = (companyID: number, userID: string, batchingTypeID: number, file: Blob): any => {
    const formData = new FormData();

    formData.append('companyID', companyID.toString());
    formData.append('userID', userID);
    formData.append('batchingTypeID', batchingTypeID.toString());
    formData.append('file', file);

    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Batching.PostTransferBatching,
            data: formData
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: BATCH_SUCCESS.type,
            onError: BATCH_FAILED.type,
            showToast: true
        }
    });
};

export const getBatching = (batchingTypeID: number, companyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            url: Endpoints.Batching.GetBatching + `?BatchingTypeID=${batchingTypeID}&CompanyID=${companyID}`,
            method: 'GET'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: GET_BATCH_SUCCESS.type,
            onError: BATCH_FAILED.type
        }
    });
};

export const getBatchingLog = (batchingID: number) => {
    return apiCallBegan({
        axiosConfig: {
            url: Endpoints.Batching.GetBatchingLog + `?BatchingID=${batchingID}`,
            method: 'GET'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: GET_BATCHING_LOG_SUCCESS.type,
            onError: BATCH_FAILED.type
        }
    });
};

export const getBatchingFile = () => {
    return apiCallBegan({
        axiosConfig: {
            url: Endpoints.Batching.GetBatchingFile(1),
            method: 'GET'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: GET_BATCH_FILE.type,
            onError: BATCH_FAILED.type
        }
    });
};

export const getTransferFile = (companyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            url: Endpoints.Batching.GetTransferFile(companyID),
            method: 'GET'
        },
        onAction: {
            onStart: BATCH_REQUESTED.type,
            onSuccess: GET_BATCH_FILE.type,
            onError: BATCH_FAILED.type
        }
    });
};