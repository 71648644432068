import { createSlice } from '@reduxjs/toolkit';
// import { IState, IInviteUserCreate } from './interfaces/InviteUser';
import { IInviteUserCreate, IState } from './interfaces/InviteUser';
import { apiCallBegan } from '../api';
import { Endpoints } from '../../constants/endpoints';

const initialState: IState = {
  loading: false,
  error: false,
  success: false,
  inviteUsers: [],
};

export const slice = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    INVITE_USER_REQUESTED: (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    },
    INVITE_USER_FAILED: (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    },
    INVITE_USER_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    INVITE_USER_GET_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.inviteUsers = action.payload.result;
    },
    INVITE_USER_DELETE_SUCCESS: (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.inviteUsers = state.inviteUsers.filter(
        (inviteUser) =>
          inviteUser.inviteUsersID !== action.payload.result.inviteUsersID
      );
    },
  },
});

export const {
  INVITE_USER_REQUESTED,
  INVITE_USER_FAILED,
  INVITE_USER_SUCCESS,
  INVITE_USER_GET_SUCCESS,
  INVITE_USER_DELETE_SUCCESS,
} = slice.actions;

export default slice.reducer;

export const GetInviteUsers = (CompanyID: number) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'GET',
      url: Endpoints.InviteUser.GetInviteUsers(CompanyID),
    },
    onAction: {
      onStart: INVITE_USER_REQUESTED.type,
      onError: INVITE_USER_FAILED.type,
      onSuccess: INVITE_USER_GET_SUCCESS.type,
    },
  });
};

export const ResendInviteUser = (inviteUserID: string, userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.InviteUser.ResendInviteUser(inviteUserID, userID),
    },
    onAction: {
      onStart: INVITE_USER_REQUESTED.type,
      onError: INVITE_USER_FAILED.type,
      onSuccess: INVITE_USER_SUCCESS.type,
      showToast: true,
    },
  });
};

export const DeleteInviteUser = (inviteUserID: string, userID: string) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'PUT',
      url: Endpoints.InviteUser.DeleteInviteUser(inviteUserID, userID),
    },
    onAction: {
      onStart: INVITE_USER_REQUESTED.type,
      onError: INVITE_USER_FAILED.type,
      onSuccess: INVITE_USER_DELETE_SUCCESS.type,
      showToast: true,
    },
  });
};

export const CreateUser = (userID: string, data: IInviteUserCreate) => {
  return apiCallBegan({
    axiosConfig: {
      method: 'POST',
      url: Endpoints.InviteUser.CreateInviteUser(userID),
      data,
    },
    onAction: {
      onStart: INVITE_USER_REQUESTED.type,
      onError: INVITE_USER_FAILED.type,
      onSuccess: INVITE_USER_SUCCESS.type,
      showToast: true,
    },
  });
};
